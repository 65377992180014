<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-form label-position="left">
        <el-row :gutter="40">
          <el-col :span="12">
            <el-form-item label="分享标题">
              <el-input v-model="form.share_title"></el-input>
            </el-form-item>
            <el-form-item label="分享描述">
              <el-input v-model="form.share_desc"></el-input>
            </el-form-item>
            <el-form-item label="分享图片">
              <y_upload_img v-model="form.share_pic"></y_upload_img>
            </el-form-item>
            <el-form-item label="底部富文本介绍">
              <br>
              <y_editor v-model="form.bottom_rich_text" eid="brt"></y_editor>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="懒加载展示图">
              <y_upload_img v-model="form.default_pic"></y_upload_img>
            </el-form-item>
            <el-form-item label="浮动按钮组主图标">
              <y_upload_img v-model="form.float_btn_main"></y_upload_img>
            </el-form-item>
            <el-form-item label="未登录提示图标">
              <y_upload_img v-model="form.login_bar_pic"></y_upload_img>
            </el-form-item>
            <el-form-item label="未登录提示标题">
              <el-input v-model="form.login_bar_title"></el-input>
            </el-form-item>
            <el-form-item label="未登录提示描述">
              <el-input v-model="form.login_bar_desc"></el-input>
            </el-form-item>
            <el-form-item align="right">
              <el-button @click="edit" type="primary">保存</el-button>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </el-card>
  </div>
</template>

<script>
import y_editor from "@/components/y-form/y-editor/y_editor";
import y_upload_img from "@/components/y-form/y-upload/y_upload_img";
export default {
  name: "edit",
  components: {
    y_editor,
    y_upload_img,
  },
  data() {
    return {
      form: {
        default_pic:"",
        float_btn_main:"",

        login_bar_pic:"",
        login_bar_title:"",
        login_bar_desc:"",
        bottom_rich_text:"",

        share_title:"",
        share_desc:"",
        share_pic:"",
      }
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    load() {
      this.$u.api.card.plugin.homePage.fastPage.conf().then(res => {
        this.form = res;
      })
    },
    edit() {
      this.$u.api.card.plugin.homePage.fastPage.confEdit(this.form).then(() => {
        this.$message.success("操作成功");
        this.load();
      })
    },
  }
}
</script>

<style scoped>

</style>